<template>
  <div>
    <div class="search_box" style="width: 100%; flex-wrap: wrap">
      <div class="search_item">
        <span style="width: 0.6rem">名称：</span>
        <el-input
          v-model="searchinput"
          placeholder="请输入名称"
          style="width: 1.5rem"
        ></el-input>
      </div>
      <div class="search_item" v-show="!hiddenlei">
        <span style="width: 0.8rem">提案类别：</span>
        <el-select
          v-model="searchlei"
          placeholder="请选择"
          filterable
          clearable
          style="width: 1.5rem"
          @change="search"
        >
          <el-option
            v-for="item in taleix"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search_item" v-show="!hiddenhuiyi">
        <span style="width: 1.2rem">提案所属会议：</span>
        <el-select
          v-model="searchhuiyi"
          placeholder="请选择"
          filterable
          clearable
          style="width: 2rem"
          @change="search"
        >
          <el-option
            v-for="item in tahy"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search_item" v-show="!hiddenbldw">
        <span style="width: 0.8rem">办理单位：</span>
        <el-select
          v-model="searchbldw"
          placeholder="请选择"
          filterable
          clearable
          style="width: 1.5rem"
          @change="search"
        >
          <el-option
            v-for="item in bldw"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search_item" v-show="!hiddenhb">
        <span style="width: 1.4rem">是否为合并提案：</span>
        <el-select
          v-model="searchhb"
          placeholder="请选择"
          filterable
          clearable
          style="width: 1rem"
          @change="search"
        >
          <el-option label="是" :value="2"> </el-option>
          <el-option label="否" :value="1"> </el-option>
        </el-select>
      </div>
      <div class="search_item" v-show="!hiddentype">
        <span style="width: 0.8rem">提案状态：</span>
        <el-select
          v-model="searchstatusvalue"
          placeholder="请选择"
          filterable
          clearable
          style="width: 1.5rem"
          @change="statusta"
        >
          <el-option label="已提交" :value="11"> </el-option>
          <el-option label="已退回" :value="12"> </el-option>
          <el-option label="接收待立案" :value="1"> </el-option>
          <el-option label="已立案" :value="2"> </el-option>
          <el-option label="已交办" :value="5"> </el-option>
          <el-option label="不立案" :value="3"> </el-option>
          <el-option label="转为意见" :value="4"> </el-option>
          <el-option label="已答复" :value="6"> </el-option>
          <el-option label="已提交意见表" :value="7"> </el-option>
        </el-select>
      </div>
      <div class="search_item">
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="info" @click="reset">重置</el-button>
      </div>
      <div class="search_item" style="">
        <slot name="otherbtn"> </slot>
        <el-button type="primary" v-show="!hiddendaochu" @click="muludaochu"
          >目录导出</el-button
        >
        <el-button type="success" v-show="!hiddendaochu" @click="pldaochu"
          >批量导出Word</el-button
        >
      </div>
    </div>

    <div class="daochucover" v-show="showcover">
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
      >
        <i
          class="el-icon-loading"
          style="color: rgb(64, 158, 255); font-size: 44px"
        ></i>
        <div style="font-size: 30px; color: rgb(64, 158, 255)">
          正在处理中....{{ daochunownumber }}/{{ daochulinumber }}
        </div>

        <el-button
          type="danger"
          @click="showcover = false"
          style="margin-top: 50px"
          >停止下载</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { generateWordBlob } from "@/utils/getword.js";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import XLSX from "xlsx-js-style";
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    otherparms: {
      //接口 其他参数
      type: Object,
      default: null,
    },
    hiddenlei: {
      type: Boolean,
      default: false,
    },
    hiddenhuiyi: {
      type: Boolean,
      default: false,
    },
    hiddentype: {
      //是否隐藏提案状态 搜索
      type: Boolean,
      default: false,
    },
    hiddenbldw: {
      //是否隐藏办理单位 搜索
      type: Boolean,
      default: false,
    },
    hiddenhb: {
      //是否隐藏是否为合并提案 搜索
      type: Boolean,
      default: false,
    },
    hiddendaochu: {
      //是否隐藏导出
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchinput: "",
      searchlei: "",
      searchhuiyi: "",
      searchhb: "",
      searchstatus: "",
      searyjstype: "",
      searchstatusvalue: "",
      searchbldw: "",
      bldw: [], //办理单位
      taleix: [], //提案类型
      tahy: [], //提案所属会议

      lianbase64: "", //立案章
      bulianbase64: "", //不立案章
      showcover: false,
      daochulinumber: 0, //批量导出总数
      daochunownumber: 0, //批量导出现在数量
    };
  },
  created() {
    //获取承办单位数据
    this.$post({
      url: "/api/organizational/index",
      params: {
        p: 1,
        size: 9999,
        pid: 3,
      },
    }).then((res) => {
      this.bldw = res.list;
    });

    //获取提案类型
    this.$post({
      url: "/api/lei/index",
      params: {
        p: 1,
        size: 9999,
      },
    }).then((res) => {
      this.taleix = res.list;
    });

    //获取提案会议
    this.$post({
      url: "/api/sshy/index",
      params: {
        p: 1,
        size: 9999,
      },
    }).then((ress) => {
      this.tahy = ress.list;
    });
  },
  methods: {
    search() {
      this.$emit("search");
    },
    reset() {
      this.$emit("reset");
    },
    statusta(e) {
      this.jstype = "";
      if (e == 11) {
        this.searchstatus = "";
        this.searyjstype = 1; //待接收
        this.$emit("search");
        return;
      }
      if (e == 12) {
        this.searchstatus = "";
        this.searyjstype = 3; //退回
        this.$emit("search");
        return;
      }
      this.searyjstype = 2;
      this.searchstatus = e;
      this.$emit("search");
    },
    //批量导出word-----------------------------------------------------------------------------------------------------------------------
    pldaochu() {
      let zip = new JSZip();
      let blobsPromises = [];
      this.$post({
        url: this.url,
        params: {
          p: 1,
          size: 9999,
          name: this.searchinput,
          lei_id: this.searchlei,
          sshy: this.searchhuiyi,
          hidtype: this.searchhb,
          type: this.searchstatus,
          jstype: this.searyjstype,
          blusers: this.searchbldw,
          ...this.otherparms,
        },
      }).then(async (res) => {
        this.showcover = true;
        this.daochulinumber = res.list.length;
        this.daochunownumber = 0;
        for (let item of res.list) {
          if (!this.showcover) {
            return;
          }

          // 获取详情并处理数据
          try {
            let rep = await this.$post({
              url: "/api/proposa/details",
              params: {
                id: item.id,
              },
            });
            let formData = JSON.parse(JSON.stringify(rep));
            // ...处理formData
            //判断是否立案  获取立案章
            if (rep.type == 3) {
              //不立案
              formData.lian_zhangbase64 = this.bulianbase64;
            } else {
              if (rep.scyj) {
                formData.lian_zhangbase64 = this.lianbase64;
              }
            }
            let data = JSON.parse(JSON.stringify(formData));
            data.wordgz = data.gz.length >= 1 ? data.gz[0].fullurlbase64 : "";
            data.wordscyjqm =
              data.shqm.length >= 1 ? data.shqm[0].fullurlbase64 : "";
            data.tian_user = data.taz ? data.taz : data.user_name[0].name;
            data.scyjrq = data.scyjrq ? data.scyjrq : "";
            data.scyj = data.scyj ? data.scyj : "";
            data.bylaly = data.bylaly ? data.bylaly : "";
            data.showfj = data.fujian.length >= 1 ? "附件列表：" : "";

            const imgSize = {
              wordgz: [50, 50],
              wordscyjqm: [100, 50],
            };
            // 清理文件名，移除不合法字符，例如换行符
            let cleanName = (formData.code + "号_" + item.name)
              .replace(/[\n\r]+/g, " ")
              .replace(/[<>:"/\\|?*]+/g, "-");
            let wordBlobPromise = generateWordBlob(
              "../static/test.docx",
              data,
              imgSize
            );
            blobsPromises.push(
              wordBlobPromise.then((blob) => ({
                name: cleanName,
                blob: blob,
              }))
            );
            this.daochunownumber++;
          } catch (error) {
            console.error(error);
          }
        }

        if (!this.showcover) {
          return;
        }
        try {
          const documents = await Promise.all(blobsPromises);
          documents.forEach((doc) => {
            zip.file(doc.name + ".docx", doc.blob);
          });
          console.log(zip.files);
          const content = await zip.generateAsync({
            type: "blob",
          });
          saveAs(content, "提案批量导出数据.zip");
          this.showcover = false;
        } catch (error) {
          console.error("Error creating zip:", error);
        }
      });
    },
    //批量导出word--------------------END---------------------------------------------------------------------------------------------------

    //导出目录excel----------------------------------------------------------------------------------------------------------------------
    muludaochu() {
      this.$post({
        url: this.url,
        params: {
          p: 1,
          size: 9999,
          name: this.searchinput,
          lei_id: this.searchlei,
          sshy: this.searchhuiyi,
          hidtype: this.searchhb,
          type: this.searchstatus,
          jstype: this.searyjstype,
          blusers: this.searchbldw,
          ...this.otherparms,
        },
      }).then((res) => {
        res.list.forEach((item, index) => {
          item.hbname = item.zid ? "已合并提案" : "未合并";
          if (item.type == 3) {
            return;
          }
          if (item.jstype == 1) {
            item.type = 11; //待接收
          }
          if (item.jstype == 3) {
            item.type = 12; //退回
          }
        });
        res.list.forEach((item, index) => {
          item.flcode = item.flcode ? item.flcode : "无";
          switch (item.type) {
            case 11:
              item.type_name = "已提交";
              break;
            case 12:
              item.type_name = "已退回";
              break;
            case 1:
              item.type_name = "接收待立案";
              break;
            case 2:
              item.type_name = "已立案";
              break;
            case 5:
              item.type_name = "已交办";
              break;
            case 3:
              item.type_name = "不立案";
              break;
            case 4:
              item.type_name = "转为意见";
              break;
            case 6:
              item.type_name = "已答复";
              break;
            case 7:
              item.type_name = "已提交意见表";
              break;
          }
        });

        // 创建工作簿
        let wb = XLSX.utils.book_new();

        // 创建工作表数据
        let ws_data = [
          ["提案目录"], // 第一行，后面将合并单元格
          [
            "提案号",
            "标题",
            "提案类别",
            "分类编号",
            "是否为合并提案",
            "提交人",
            "提交时间",
            "状态",
          ], // 第二行，列头
        ];

        res.list.forEach((participant) => {
          let row = [
            participant.code,
            participant.name,
            participant.lei_name,
            participant.flcode,
            participant.hbname,
            participant.user_name,
            participant.addtime,
            participant.type_name,
          ];
          ws_data.push(row);
        });

        // 创建带有数据的工作表
        let ws = XLSX.utils.aoa_to_sheet(ws_data);

        // 设置工作表的列宽
        ws["!cols"] = [
          {
            wch: 20,
          }, // 提案号列宽
          {
            wch: 50,
          }, // 标题列宽
          {
            wch: 30,
          }, // 提案类别列宽
          {
            wch: 30,
          }, // 分类编号
          {
            wch: 20,
          }, // 是否为合并提案列宽
          {
            wch: 20,
          }, // 提交人列宽
          {
            wch: 40,
          }, // 提交时间列宽
          {
            wch: 30,
          }, // 状态列宽
        ];

        // 第一行标题样式
        const titleStyle = {
          font: {
            name: "宋体",
            sz: 18,
            bold: true,
          },
          alignment: {
            horizontal: "center",
            vertical: "center",
          },
        };

        // 第二行列头样式
        const headerStyle = {
          font: {
            name: "宋体",
            sz: 15,
            bold: true,
          },
          alignment: {
            horizontal: "center",
            vertical: "center",
          },
          border: {
            top: {
              style: "thin",
            },
            bottom: {
              style: "thin",
            },
            left: {
              style: "thin",
            },
            right: {
              style: "thin",
            },
          },
        };

        // 数据样式
        const dataStyle = {
          font: {
            name: "宋体",
            sz: 14,
          },
          alignment: {
            horizontal: "center",
            vertical: "center",
          },
          border: {
            top: {
              style: "thin",
            },
            bottom: {
              style: "thin",
            },
            left: {
              style: "thin",
            },
            right: {
              style: "thin",
            },
          },
        };

        // 应用标题样式并合并第一行的单元格
        XLSX.utils.sheet_add_aoa(ws, [["提案目录"]], {
          origin: "A1",
        });
        ws["A1"].s = titleStyle;
        ws["!merges"] = [XLSX.utils.decode_range("A1:H1")];

        // 应用列头样式
        ["A2", "B2", "C2", "D2", "E2", "F2", "G2", "H2"].forEach((key) => {
          ws[key].s = headerStyle;
        });

        // 应用数据样式
        for (let R = 3; R <= ws_data.length; R++) {
          for (let C = 65; C <= 72; C++) {
            // ASCII 码 65 是 'A', 72 是 'H'
            let cell_ref = String.fromCharCode(C) + R; // 生成单元格引用，例如 'A3'
            ws[cell_ref].s = dataStyle;
          }
        }

        // 把工作表添加到工作簿
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        // 定义导出的文件名
        let exportFileName = "提案目录.xlsx";

        // 导出 Excel 文件
        XLSX.writeFile(wb, exportFileName, {
          bookType: "xlsx",
          type: "binary",
        });
      });
    },
    //导出目录excel-------------------------------END---------------------------------------------------------------------------------------
  },
};
</script>

<style lang="scss" scoped>
.search_box {
  display: flex;
  align-items: center;
  font-size: 0.16rem;

  .search_item {
    display: flex;
    align-items: center;
    margin-right: 0.15rem;
    margin-top: 15px;
  }
}
.daochucover {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
